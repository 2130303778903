import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom/cjs/react-router-dom.min'
import getApiCall from '../../Services/getApiCall';
import Timer from "../../Components/Timer";
import {formatDate, getDateTime, propertyTitle} from "../../Services/helpers";
import {Link} from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Constant from "../../Components/Constant";
import loginUser from "../../Services/loginUser";

 
function PropertyDetail(props) {
    let {accessToken} = loginUser();

    const socket = props.socket;
    const history = useHistory();
    const [details, setDetails] = useState()
    const [bid, setBids] = useState([]);
    const [isFound, setIsFound] = useState(true);
    const [openBid, setOpenBid] = useState("NA")
    const [highestBid, setHighestBid] = useState("NA")
    const [joinRoom, setJoinroom] = useState(false);
    const [connected, setConnected] = useState(socket.connected)
    const [time, setTimmer] = useState({})
    const [bidder, setBidder] = useState([])
    const [hblFileName, setHblFileName] = useState("")

    let {id} = useParams();


    function getTimeRemaining(endtime) {
        const total = Date.parse(endtime) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        return {
            total,
            days,
            hours,
            minutes,
            seconds
        };
    }

    async function getBidList(){
        console.log("called")
        return new Promise( (resolve,reject) => {
            const timeout = setTimeout(() => {
                resolve({status: 400, message: "API could not fullfil the request!"});
              }, 8000);

            socket.on('get_chat_admin', (data) => {
                let records = ""
                if (data.meta.status == true) {
                    let items = data.data
                    items = items ? items.filter((item) => {
                        return item.isPrebid == false
                    }) : []
    
    
                    if (items.length >= 1) {
                        // setOpenBid(data.data[0].message)
                        // setHighestBid(data.data[data.data.length - 1].message)
                        setOpenBid(items[items.length - 1].message)
                        setHighestBid(items[0].message)
                        records = items.map((rec, index) => {
                            // return  !rec.isPrebid ?  (<tr key={`${rec.bidder.bidderId}_${index}`}>
                            return (<tr key={`${rec.bidder.bidderId}_${index}`}>
                                <td>{rec.message}</td>
                                <td>{rec.bidder.bidderId ?? '-'}</td>
                                <td>{moment(rec.createdAt).utc().format('DD-MM-YYYY HH:MM:SS')}</td>
                            </tr>);
                        })
                        console.log("=====1",records)
                        
                        resolve(records);
                        clearTimeout(timeout);
                      
                    } else {
                        records = <tr>
                            <td>No bid</td>
                        </tr>
                        console.log("=====2",records)
                       
                         resolve(records);
                         clearTimeout(timeout);
                         
                    }
    
                } else {
                    records = <tr>
                        <td>No bid</td>
                    </tr>
                        console.log("=====3",records)
                       
                     resolve(records);
                     clearTimeout(timeout);
                }
               
            });
        })

    }
// console.log(time)
    async function getPropertyDetailsById(id) {
        setIsFound(false)
        let response = await getApiCall('user/property/getDetailsById/' + id);
        if (response.meta.status) {
            setDetails(response.data);
        }

        // let data = await getBidList();
        // console.log("dfdfdfd",data)
        // setBids(data)
        // socket.on('get_chat_admin', (data) => {
        //     let records = ""
        //     if (data.meta.status == true) {
        //         let items = data.data
        //         items = items ? items.filter((item) => {
        //             return item.isPrebid == false
        //         }) : []


        //         if (items.length >= 1) {
        //             // setOpenBid(data.data[0].message)
        //             // setHighestBid(data.data[data.data.length - 1].message)
        //             setOpenBid(items[items.length - 1].message)
        //             setHighestBid(items[0].message)
        //             console.log(items)
        //             records = items.map((rec, index) => {
        //                 // return  !rec.isPrebid ?  (<tr key={`${rec.bidder.bidderId}_${index}`}>
        //                 return (<tr key={`${rec.bidder.bidderId}_${index}`}>
        //                     <td>{rec.message}</td>
        //                     <td>{rec.bidder.bidderId ?? '-'}</td>
        //                     <td>{moment(rec.createdAt).utc().format('DD-MM-YYYY HH:MM:SS')}</td>
        //                 </tr>);
        //             })
        //         } else {
        //             records = <tr>
        //                 <td>No bid</td>
        //             </tr>
        //         }

        //     } else {
        //         records = <tr>
        //             <td>No bid</td>
        //         </tr>
        //     }
        //     setBids(records);
        //     setIsFound(true)
        // });

        socket.on('connect_error', (error) => {
            console.log(error);
            setTimeout(() => socket.connect(), 5000)
        });

        socket.on('disconnect', () => console.log({'time': 'server disconnected'}))
    }

    async function RoomJoinInit(id) {
        if (joinRoom == false) {
            await socket.on('connect', () => console.log('socket_id => ' + socket.id));
            await socket.emit('join_room', {'propertyId': id});
            setJoinroom(true)

        } else {
            console.log("room already joined")
        }
    }

    function renderBidList() {
        history.push(`/liveBidderList/${id}`)
    }


    async function downloadExcel() {
        let response = await getApiCall(`user/bid/bidder/letter/644cd52378fa9a3ef4981d20`);
        if (response.meta.status) {
            // console.log(response.data)
            var csvString = response.data;
            var universalBOM = "\uFEFF";
            var a = window.document.createElement('a');
            a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString));
            a.setAttribute('download', 'exportLeadList.csv');
            window.document.body.appendChild(a);
            a.click();
            // window.location.reload();
        }

    }

    async function downloadPDF2() {
        let response = await getApiCall(`user/bid/bidder/letter/644cd52378fa9a3ef4981d20`);
        if (response.meta.status) {
            var pdfData = response.data;
            // Create a Blob from the PDF data
            var blob = new Blob([pdfData], {type: 'application/pdf'});

            // Create a temporary link element to trigger the download
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'exportLeadList.pdf';
            document.body.appendChild(link);
            link.click();
            // document.body.removeChild(link);
        }
    }

    async function downloadPDF3() {
        let response = await getApiCall(`user/bid/bidder/letter/644cd52378fa9a3ef4981d20`);
        if (response instanceof Blob) {
            // Create a temporary link element to trigger the download
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(response);
            link.download = 'exportLeadList.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    async function downloadPDF() {
        try {
            const config = {
                headers: {
                    authkey: accessToken
                },
                responseType: 'blob'
            };
            const response = await axios.get(Constant.apiBasePath + 'user/bid/bidder/letter/' + details?._id, config);
            const pdfData = response.data;

            // Create a Blob from the PDF data
            const blob = new Blob([pdfData], {type: 'application/pdf'});

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = hblFileName+'.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            // Handle any errors
            console.error('Error downloading PDF:', error);
        }
    }


    useEffect(() => {
        RoomJoinInit(id)
        getPropertyDetailsById(id)

        // update records
        function updateBids(data) {
            let records = ""
            if (data.meta.status == true) {
                let items = data.data
                items = items ? items.filter((item) => {
                    return item.isPrebid == false
                }) : []

                if (items.length >= 1) {
                    setOpenBid(items[items.length - 1].message)
                    setHighestBid(items[0].message)
                    records = items.map((rec, index) => {
                        return (<tr key={`${rec.bidder.bidderId}_${index}`}>
                            <td>{rec.message}</td>
                            <td>{rec.bidder.bidderId ?? '-'}</td>
                            <td>{formatDate(rec.createdAt)}</td>
                        </tr>);
                    })
                    setBids(records);
                    setIsFound(true)
                } else {
                    records = <tr><td>No bid</td></tr>
                    setBids(records);
                    setIsFound(false)
                }
            } else {
                records = <tr><td>No bid</td></tr>
                setBids(records);
                setIsFound(false)
            }
        }

        function updateBidder(data){
            let records = ""
            if (data.meta.status == true) {
                if (data.data.length >= 1) {
                  let records =   data.data.map((item, index) => {
                    console.log(item)
                            let status = '';
                            if (item?.isVerified) {
                                status = "Accepted"
                            } else if (!item?.isVerified && !item?.bidderRejectReason) {
                                status = 'Pending';
                            } else if (!item?.isVerified && item?.bidderRejectReason) {
                                status = 'Rejected';
                            }
                            setHblFileName(`${item.propertyDetails.auctionId}_${item.propertyDetails.propertyId}_hbl`)
                            return <tr key={index}>
                                <td>{(index + 1)}</td>
                                <td>{item.bidder.createdAt.split("T")[0]}</td>
                                <td><a href={`/bidder/bidder_overviews/${item._id}`}>{item.order.participationId !== undefined ? item.order.participationId : "-"}</a></td>
                                <td>{item.order.isSigned == true ? "Done": "Pending"}</td>
                                <td>{item.bidder.type}</td>
                                <td>{item.bidder.type != "COMPANY" ? item.bidder.name: item.bidder.companyName}</td>
                                <td>{item.bidder.email}</td>
                                <td>{item.bidder.mobile}</td>
                                <td>{item.partner == undefined ? "-" : item.partner.name}</td>
                                <td>{item.order.isEoi === true ? "EOI" : "EMD"}</td>
                                <td>{item.propertyDetails.propertyId}</td>
                                <td>{item.propertyDetails.auctionId}</td>
                                <td>{item.propertyDetails.auctionDate}</td>
                                <td>{item.propertyDetails.auctionStatus}</td>
                              
                                <td>{item.order.amount}</td>
                                <td>{item.propertyDetails.stateName}</td>
                                <td>{item.propertyDetails.cityName}</td>
                                <td>{item.propertyDetails.price}</td>
                                <td>{item.propertyDetails.auctionStatus == "COMPLETED" ? item.isBidAwarded === true ? "Win" : "Lost" : "-"}</td>
                               
                            </tr>
                    })
                    setBidder(previous => [...previous, records]); 
                }else{
                    records = <tr><td colspan={15} style={{textAlign: 'center'}}>No Records</td></tr>
                    setBidder(previous => [...previous, records]); 
                }
            }else{
                records = <tr><td colspan={15} style={{textAlign: 'center'}}>No Records</td></tr>
                setBidder(previous => [...previous, records]);
            }
            
        }
        
        // add connect of socket
        function addConnect(){
          setConnected(true)
        }

        // check & update socket status
        function updateConnection(){
          setConnected(false)
        }

        socket.timeout(5000).on('get_chat_admin',updateBids);
        socket.timeout(5000).on('get_top_bidder',updateBidder);

          return () => {
            socket.off('connect', addConnect);
            socket.off('disconnect', updateConnection);
            socket.off('get_chat_admin', updateBids);
          };

    }, [])


    return (
        <>
            <div className="propertyDetails mt-4 container-fluid">
                <div className="main-title">
                    <h3>Auction Details</h3>
                </div>
                <div className="row">
                    <div className='col-md-12 text-right'>
                        <button type="submit" className="btn btn-warning btn-sm" onClick={() => history.goBack()}>Go
                            Back
                        </button>
                    </div>
                </div>

                <div className="card h-100 py-2 mt-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="row no-gutters align-items-center">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className='media'>
                                            <div className='media-left'><i
                                                className="fas fa-calendar-check fa-2x text-gray-700"></i></div>
                                            <div className='media-body ml-4'>
                                                <h5 className="font-weight-bold text-gray-700">Auction Start
                                                    Date: <strong
                                                        className="orangetext">{details?.auctionStartDateTimeEpoch ? getDateTime(details.auctionStartDateTimeEpoch)?.date.toLocaleDateString() : ''}
                                                    </strong> Time <strong
                                                        className="orangetext">{details?.auctionStartDateTimeEpoch ? getDateTime(details.auctionStartDateTimeEpoch)?.date.toLocaleTimeString() : ''}</strong>
                                                </h5>
                                                <h5 className="font-weight-bold text-gray-700">
                                                    Auction End Date: <strong
                                                    className="orangetext">{details?.auctionExtendedDateTimeEpoch ? getDateTime(details.auctionExtendedDateTimeEpoch)?.date.toLocaleDateString() : ''}
                                                </strong> Time <strong
                                                    className="orangetext">{details?.auctionExtendedDateTimeEpoch ? getDateTime(details.auctionExtendedDateTimeEpoch)?.date.toLocaleTimeString() : ''}</strong>
                                                </h5>
                                                <h5
                                                    className="font-weight-normal text-gray-700">Time
                                                    Left In
                                                    Auction
                                                    Ending: {details?.auctionExtendedDateTimeEpoch ?
                                                        <strong> <Timer
                                                            endDateTime={details?.auctionExtendedDateTimeEpoch}/></strong> : ''}
                                                </h5>
                                            </div>
                                        
                                            <div className='media-right'>
                                                <button className="btn btn-primary " type={"button"}
                                                    // onClick={() => downloadExcel()}
                                                        onClick={() => downloadPDF()} disabled={details?.isAwarded === true ? false : true}
                                                >Download HBL
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="col-12 col-sm-3 col-md-3 col-lg-3 text-right">
                                        <Link className='bid-hand d-inline' to={`/liveBidderList/${id}`}>
                                            <img src='../../assets/images/auction.png'/>
                                        </Link>
                                    </div> */}

                                    <div>


                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="row no-gutters align-items-center">
                                    <div className="col">
                                        <div className="h6 mb-0 font-weight-bold text-gray-800">Property Code/Listing
                                            ID:<strong className="orangetext"> {details?.propertyId}</strong></div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="row no-gutters align-items-center">
                                    <div className="col">
                                        <div className="h6 mb-0 font-weight-bold text-gray-800">Property Name:<strong
                                            className="orangetext"> {propertyTitle(details)}</strong>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="row no-gutters align-items-center">
                                    <div className="col">
                                        <div className="h6 mb-0 font-weight-bold text-gray-800">Opening Bid: <strong
                                            className="orangetext">{openBid}</strong></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6 mb-4">
                                <div className="row no-gutters align-items-center">
                                    <div className="col">
                                        <div className="h6 mb-0 font-weight-bold text-gray-800">Highest Bid: <strong
                                            className="orangetext">{highestBid}</strong></div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col-xl-6 col-md-6 mb-4">
                                {
                                    isFound ?
                                        <div className="table-responsive mb-3">
                                            <table className="table table-bordered table-striped">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Bidders ID</th>
                                                    <th scope="col">Time</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {bid}
                                                </tbody>
                                            </table>

                                        </div>
                                        : <>
                                        <h5 className={'text-center'}>No Bid Found</h5>
                                        </>
                                }

                                {/* <div className="row no-gutters align-items-center">
                                            <div className="col-auto mr-3">
                                                <h4 className="pricetext">Rs. 34,35,000</h4>
                                            </div>
                                            <div className="col">
                                                <div className="h6 mb-0 font-weight-bold text-gray-800">Highest Bid:</div>
                                            </div>

                                        </div> */}

                            </div>
                        </div>

                        <hr></hr>
                        <h6>Bidders</h6>

                        <div className="table-responsive">
                            <table className="table table-bordered" width="100%" cellSpacing="0" style={{display: 'block', overflowX: 'auto', whiteSpace: 'nowrap'}}>
                                <thead>
                                <tr>
                                    <th>Sl. No.</th>
                                    <th>Date of registration</th>
                                    <th>Participation ID</th>
                                    <th>e-sign Status</th>
                                    <th>Bidder Type</th>
                                    <th>Bidder Name</th>
                                    <th>Bidder Email</th>
                                    <th>Bidder Mobile</th>
                                    <th>Financial Institution Name</th>
                                    <th>Auction Type</th>
                                    <th>Property ID</th>
                                    <th>Auction Id</th>
                                    <th>Auction Date</th>
                                    <th>Auction Status</th>
                                    <th>EMD payment amount</th>
                                    <th>Property State</th>
                                    <th>Property City</th>
                                    <th>Property Reserve Price</th>
                                    <th>Bidder Status</th>
                                   
                                </tr>
                                </thead>

                                <tbody>
                                        {bidder}
                                </tbody>

                                </table>
                                </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyDetail