export function Logout() {
    // confirm the user wants to logout
    if (window.confirm("Are you sure you want to logout?")) {
        sessionStorage.clear();
        window.location.href = '/login';
    }

}

export function userDetails() {
    let data = sessionStorage.getItem('userDetails');
    data = JSON.parse(data);
    return data;
}
