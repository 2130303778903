import React, { useState, useEffect } from "react";
import { Nav } from "rsuite";
import getApiCall from "../../Services/getApiCall";
import useGetRoleModule from "../../Services/useGetRoleModule";
import { blobUrl } from "../../Services/helpers";
import { formatDate } from "../../Services/helpers";
import { Document, Page, pdfjs } from "react-pdf";
import Constant from "../../Components/Constant";
import {toast, ToastContainer} from "react-toastify";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BidderOverview = (props) => {
  const [active, setActive] = useState("home");
  const [overview, setOverview] = useState({});
  const [eSignDocumentBase64, setESignDocumentBase64] = useState({ msg: null });
  const [saleCertificate, setSaleCertificate] = useState("");
  const [permission, setPermission] = useState({});
  const [paymentHistoryList, setPaymentHistory] = useState([])
  const [numPages, setNumPages] = useState(0);

  function getList() {
    let bidderId = props.match.params.id;
    getApiCall("user/bid/bidder/details/" + bidderId)
      .then((response) => {
        if (response.meta.status) {
          let paymentHistory = []
          let order = response.data.order
          let istallment = response.data.installment
          let { transactionId , paymentStatus, refundAmount, bankName, accountName, receiptExpiryDate, refundTransactionId, IFSCCode, documentLink, accountNumber , paymentDetails} = order;
          
          if(paymentDetails != undefined){
          let { events, amountPaid, business, callerReferenceNo,gateway,transactionReferenceNumber,merchantTransactionId,modeOfPayment,parentMerchantTransactionId,paymentRequestDate,paymentReceiveDate,paymentType,product, prospectNumber, sourceApp ,status, statusDescription} = paymentDetails
            paymentHistory.push({ transactionId , paymentStatus, refundAmount, bankName, accountName, IFSCCode, receiptExpiryDate, refundTransactionId, documentLink, amount: paymentDetails.amountPaid, accountNumber, events, amountPaid, business, callerReferenceNo,gateway,transactionReferenceNumber,merchantTransactionId,modeOfPayment,parentMerchantTransactionId,paymentRequestDate,paymentReceiveDate,paymentType,product, prospectNumber, sourceApp ,status, statusDescription})

            istallment.map(_ => {
              let { transactionId , paymentStatus, refundAmount, bankName, accountName, receiptExpiryDate, refundTransactionId, IFSCCode, documentLink, accountNumber , paymentDetails} = _;
              let { events, amountPaid, business, callerReferenceNo,gateway,transactionReferenceNumber,merchantTransactionId,modeOfPayment,parentMerchantTransactionId,paymentRequestDate,paymentReceiveDate,paymentType,product, prospectNumber, sourceApp ,status, statusDescription} = _.paymentDetails
            
              paymentHistory.push({ transactionId , paymentStatus, refundAmount, bankName, accountName, IFSCCode,receiptExpiryDate, refundTransactionId, documentLink, amount: paymentDetails.amountPaid, accountNumber, events, amountPaid, business, callerReferenceNo,gateway,transactionReferenceNumber,merchantTransactionId,modeOfPayment,parentMerchantTransactionId,paymentRequestDate,paymentReceiveDate,paymentType,product, prospectNumber, sourceApp ,status, statusDescription})
            })
          }
       
       
          setPaymentHistory(paymentHistory)
          setOverview(response.data);
        } else {
          alert("page go back due to older record")
          window.history.back()
          // setOverview(response.data);
         
        }
      })
      .catch((error) => {
        console.log(error)
        setOverview({});
      });
  }

  async function GetRole() {
    let Role = await useGetRoleModule("bidders");
    setPermission(Role);
    getList();
  }

  async function loadESignDocument(id, documentLink) {
    let name = overview.bidder?.name.replace(" ", "_")
    let propertIds = overview.propertyDetails?.propertyId
    console.log(documentLink)
    if(id != ""){
    getApiCall("user/leegality/admin/signdoc/" + id)
      .then((response) => {
        if (response.meta.status) {
          setESignDocumentBase64(response.meta.msg);
          setSourceValue(response.meta.msg.file);
          let fetchDataModified = `data:application/pdf;base64,${response.meta.msg.file }`;
                let a = document.createElement("a");
                a.href = fetchDataModified;
                a.download = name+"_"+propertIds+"_"+'tenderdoc.pdf';
                a.click();
                toast("E-sign tender Form Downloaded")
        } else {
          setESignDocumentBase64({ msg: null });
        }
      })
      .catch((error) => {
        setESignDocumentBase64({ msg: null });
      });
    }else{
      let url = blobUrl(documentLink)
      window.open(url);
    }
  }

  async function loadSaleCertificate() {
      let bidderId = props.match.params.id;
      window.open(`${Constant.apiBasePath}common/sale/admin/letter/${bidderId}`);
      // setSaleCertificate(`${Constant.apiBasePath}common/sale/admin/letter/${bidderId}`);
  }

  const getStartingValue = () => {
    try {
      return sessionStorage.getItem("pdfSource");
    } catch {
      return "";
    }
  };

  const getStartingSaleCertificateValue = () => {
    try {
      return sessionStorage.getItem("saleCertificate");
    } catch {
      return "";
    }
  };

  const [source, setSource] = useState(getStartingValue());
 
  const clearPdfDate = () => {
    sessionStorage.removeItem("pdfSource");
    sessionStorage.removeItem("saleCertificate");
  };

  function range(count) {
    return Array(count)
      .fill(0)
      .map((_, idx) => idx);
  }

  const setSourceValue = (newValue) => {
    if (!newValue) {
      console.info("Nothing to paste");
      return;
    }

    sessionStorage.setItem("pdfSource", newValue);
    setSource(newValue);
  };


  const onDocumentLoadSuccess = (doc) => {
    setNumPages(doc.numPages);
  };

  useEffect(() => {
    GetRole();
  }, []);

  return (
    <>
      {permission.hasOwnProperty("moduleAccress") &&
      !permission.moduleAccress ? (
        <div className="row text-center">
          <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
            <div className="errer">
              <img src="/program-error.png" />
              <h2>403</h2>
              <p>{permission.message}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
             <ToastContainer/>
          <div className="main-title">
            <h3>Bidders Ovierview</h3>
          </div>
     
         
          <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="justify-content-center mt-2">
                      <div id="primary" className="card">
                      <div className="card-header">
                      <h5>Basic Details</h5>
                      </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <div className="dec-text">
                              <p>
                                <strong>Name :</strong> { overview.bidder?.type != "COMPANY" ? overview.bidder?.name : overview.bidder?.nameOfAuthorizedRepresentative}
                              </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>Father Name :</strong>{" "}
                                { overview.bidder?.type != "COMPANY" ? overview.bidder?.fatherName : overview.bidder?.fatherNameOfAuthorizedRepresentative}
                                {/* {overview.bidder?.fatherName} */}
                              </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>DOB :</strong> {overview.bidder?.dob}
                              </p>
                              </div>
                              </div>
                            
                            <div className="col-md-4 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>Area :</strong> {overview.bidder?.area}
                              </p>
                            </div>
                            </div>
                            <div className="col-md-4 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>Mobile :</strong>{" "}
                                {overview.bidder?.mobile}
                              </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>Email :</strong>{" "}
                                {overview.bidder?.email}
                              </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>PAN Number :</strong>{" "}
                                {overview.bidder?.PANNumber.replace(
                                  /^.{4}/g,
                                  "XXXX"
                                )}
                              </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>Address :</strong>{" "}
                                {overview.bidder?.address}
                              </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>Pin Code :</strong>{" "}
                                {overview.bidder?.pinCode}
                              </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>City :</strong> {overview.bidder?.city}
                              </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>State :</strong>{" "}
                                {overview.bidder?.state}
                              </p>
                              </div>
                            </div>

                            {/* <div className="col-md-4 col-12">
                                    <p><strong>Account Number :</strong> {overview.bidder.accountNumber}</p>
                                </div>
                                <div className="col-md-4 col-12">
                                    <p><strong>Bank Name :</strong> {overview.bidder.bankName}</p>
                                </div>
                                <div className="col-md-4 col-12">
                                    <p><strong>Account Name :</strong> {overview.bidder.accountName}</p>
                                </div>
                                <div className="col-md-4 col-12">
                                    <p><strong>IFSC Code :</strong> {overview.bidder.IFSCCode}</p>
                                </div>

                                <div className="col-md-4 col-12">
                                    <p><strong>Company Name :</strong> {overview.bidder.companyName}</p>
                                </div>
                                <div className="col-md-4 col-12">
                                    <p><strong>Authorized Representative :</strong> {overview.bidder.nameOfAuthorizedRepresentative}
                                    </p>
                                </div>
                                <div className="col-md-4 col-12">
                                    <p><strong>GST Number :</strong> {overview.bidder.GSTNumber}</p>
                                </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          
                <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="card py-2">
                    <div className="card-header">
                    <h5>KYC documents</h5>
                      </div>
                      <div className="card-body">
                        <div className="justify-content-center mt-2">
                          {/* document cards */}
                          <div className="row">
                            <div className="col-3 col-md-3 col-xs-3 col-l-3">
                              <div className={"mb-4"}>
                                <img
                                  src={blobUrl(overview.bidder?.profilePic)}
                                  alt="Avatar"
                                  className="avatar"
                                  width={160}
                                />
                                <h6>Profile Picture</h6>
                              </div>
                            </div>
                          </div>
                          <div className="flex-wrap d-flex list-images">
                            <div className="list-images-inline">
                              
                                <img
                                  src={blobUrl(overview.bidder?.AadharImage)}
                                  alt="Avatar"
                                  className="avatar"
                                />
                                <br />
                                <h6>
                                  <strong>Aadhaar Card Front View</strong>
                                </h6>
                                <p>
                                  <strong>Status : </strong>{" "}
                                  {overview.bidder?.aadharStatus}
                                </p>
                                <br />
                                {overview.bidder?.aadharRejectReason &&
                                overview.bidder?.aadharRejectReason != "" ? (
                                  <p>
                                    <strong>Reject Reason : </strong>{" "}
                                    {overview.bidder?.aadharRejectReason}
                                  </p>
                                ) : (
                                  ""
                                )}
                            </div>

                            <div className="list-images-inline">
                                <img
                                  src={blobUrl(
                                    overview.bidder?.AadharImageBack
                                  )}
                                  alt="Avatar"
                                  className="avatar"
                                />
                                <br />
                                <h6>
                                  <strong>Aadhaar Card Back View</strong>
                                </h6>
                                <p>
                                  <strong>Status : </strong>{" "}
                                  {overview.bidder?.aadharStatus}
                                </p>
                                <br />
                                {overview.bidder?.aadharRejectReason &&
                                overview.bidder?.aadharRejectReason != "" ? (
                                  <p>
                                    <strong>Reject Reason : </strong>{" "}
                                    {overview.bidder?.aadharRejectReason}
                                  </p>
                                ) : (
                                  ""
                                )}
                            </div>
                            <div className="list-images-inline">
                                <img
                                  src={blobUrl(overview.bidder?.PANImage)}
                                  alt="Avatar"
                                  className="avatar"
                                />
                                <br />
                                <h6>
                                  <strong>PAN Card</strong>
                                </h6>
                                <p>
                                  <strong>Status : </strong>{" "}
                                  {overview.bidder?.panStatus}
                                </p>
                                <br />
                                {overview.bidder?.panRejectReason &&
                                overview.bidder?.panRejectReason != "" ? (
                                  <p>
                                    <strong>Reject Reason : </strong>{" "}
                                    {overview.bidder?.panRejectReason}
                                  </p>
                                ) : (
                                  ""
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
         
                <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="card py-2">
                    <div className="card-header">
                    <h5>Refund Bank Details</h5>
                    </div>
                      <div className="card-body">
                        <div className="justify-content-center mt-2">
                          <div className="row">

                          <div className="col-md-3 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>Bank Name:</strong>{" "}
                                {overview.order?.bankName}
                              </p>
                              </div>
                            </div>

                            <div className="col-md-3 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>Account Name:</strong>{" "}
                                {overview.order?.accountName}
                              </p>
                              </div>
                            </div>

                            
                            <div className="col-md-3 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>Bank Account No:</strong>{" "}
                                {overview.order?.accountNumber}
                              </p>
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                            <div className="dec-text">
                              <p>
                                <strong>IFSC Code:</strong>{" "}
                                {overview.order?.IFSCCode}
                              </p>
                              </div>
                            </div>
                           
                            <div className="col-md-4 col-12">
                            <div className="dec-text">
                              <p className="mb-3">
                                <strong>Cancel Cheque</strong>{" "}
                              </p>
                              <img
                                src={blobUrl(overview.order?.cancelCheque)}
                                alt="Avatar"
                                className="avatar img-fluid"
                              />
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          
                <div className="row">
                  <div className="col-xl-12 col-md-12">
                    <div className="card py-2">
                    <div className="card-header">
                    <h5>EMD Payment Details</h5>
                    </div>
                      <div className="card-body">
                        <div className="justify-content-center mt-2">
                          <div className="table-responsive">
                            <h6 style={{ marginBottom: 10 }}>Payment Status</h6>
                            <table
                              className="table table-bordered"
                              width="100%"
                              cellSpacing="0"
                            >
                              <thead>
                                <tr>
                                  
                                  <th>Amount</th>
                                  
                                  <th>Business</th>
                                  <th>Caller reference no</th>
                                  <th>Gateway</th>
                                  <th>Merchant transaction id</th>
                                  <th>Mode of payment</th>
                                  <th>Payment receive date</th>
                                  <th>Payment request date</th>
                                  <th>Payment status</th>
                                  <th>Payment type</th>
                                  <th>Product</th>
                                  <th>Prospect number</th>
                                  <th>Source app</th>
                                  <th>Status</th>
                                  <th>Transactionid</th>
                                  <th>Refund date/time</th>
                                  <th>Refund transactionid</th>
                                </tr>
                              </thead>

                              <tbody>
                            
                                {paymentHistoryList.length > 0 ?
                                paymentHistoryList.map( (payment, i) => {
                                return <tr key={i}>
                                          
                                          <td>{payment.amountPaid}</td>
                                         
                                          <td>{payment.business}</td>
                                          <td>{payment.callerReferenceNo}</td>

                                          <td>{payment.gateway}</td>
                                          <td>{payment.merchantTransactionId}</td>
                                          <td>{payment.modeOfPayment}</td>
                                          <td>{payment.paymentReceiveDate}</td>
                                          <td>{payment.paymentRequestDate}</td>
                                          <td>{payment.paymentStatus}</td>
                                          <td>{payment.paymentType}</td>
                                          <td>{payment.product}</td>
                                          <td>{payment.prospectNumber}</td>
                                          <td>{payment.sourceApp}</td>
                                          <td>{payment.status}</td>
                                          <td>{payment.transactionId}</td>
                                          <td>{payment.receiptExpiryDate == "" ? "-" : payment.receiptExpiryDate }</td>
                                          <td>{payment.refundTransactionId == "" ? "-" : payment.refundTransactionId}</td>
                                        
                                        </tr>
                                        }) : null }
                              </tbody>
                            </table>
                          </div>
                       
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card py-2">
                    <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                    <h5>E-sign Tender Document</h5>
              <button className="btn btn-sm btn-primary" onClick={() => loadESignDocument(overview?.order?.documentId, overview?.order?.documentLink)} disabled={overview?.order?.documentId == "" && overview?.order?.documentLink == ""}>Download E-sign Tender Document</button>
            </div> 
                    </div>
                      <div className="card-body"></div>
                      </div>
                      <div className="card py-2">
                    <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                    <h5>Sale Certificate</h5>
                    <button className="btn btn-sm btn-primary" disabled={!overview.isHighestBidder} onClick={() => loadSaleCertificate()}>Download Sale Certificate</button>
            </div> 
                    </div>
                      <div className="card-body"></div>
                      </div>         
                
        </div>
      )}
    </>
  );
};

export default BidderOverview;
