import React from 'react'
import {Redirect, Route} from 'react-router-dom'


const AdminAccess = ({ component: Component, socket, ...rest }) => {
    let token = sessionStorage.getItem('loginDetails');
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                token ? (
                    <Route
                        {...routeProps}
                        render={(props) => (
                            <Component {...props} socket={socket} />
                        )}
                    />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};

const AdminAccessOLD = ({component: Component, socket,...rest}) => {
    let token = sessionStorage.getItem('loginDetails');
    // let getDetails = JSON.parse(token);
    return (
        <Route
            {...rest}
            render={props =>
                (token) ?
                    (
                        <><Component {...{props,socket}} /></>
                    ) :
                    (<Redirect to="/"/>)
            }
        />
    )
}

export default AdminAccess