import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import swal from 'sweetalert';
import $ from 'jquery';

import EnableDisable from "../../Widgets/EnableDisable";
import DeleteBtn from "../../Widgets/DeleteBtn";
import Pagination from "../../Widgets/Pagination";
import getApiCall from "../../Services/getApiCall";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import useGetRoleModule from '../../Services/useGetRoleModule';

const FaqsListing = () => {

    const [allData, setAllData] = useState('');
    // const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(0);
    const [next, setNext] = useState(0);
    const [previous, setprevious] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [searchBoxText, setSearchBoxText] = useState('')
    const [tableData, setTableData] = useState([])
    const [isFilterError, setFilterError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Required *');
    const [pageName] = useState("FAQs");
    const [pageUrl] = useState("/faqs");
    const [permission, setPermission] = useState({})

    /*
    *
    * @params page : page of pagination
    * @params filter: filter query
    * if filter '' and page 1 it runs default
    * */
    const CALLAPI = async (page, filter) => {
        let searchTxt = $('#searchTxt').val()
        // getApiCall('admin/faqs/getAll/' + page, {
        getApiCall('common/faq/list', {
            searchText: searchTxt,
            filter: filter
        })
            .then(data => {
                if (data.meta.status) {
                    // let pagination = data.data.pagination;
                    //    setTotal(pagination.Total);
                    // setCurrent(pagination.curr);
                    // setNext(pagination.next);
                    // setprevious(pagination.prev);
                    // setTotalPage(pagination.total);
                    setAllData(data.data);
                    setLoading(true);
                }
            })
            .catch(err => {
                if (err.response === undefined) {
                    swal({text: "API OFFLINE", icon: "warning", dangerMode: true});
                    setLoading(false);
                    return false;
                }
                let API_MESSAGE = err.response.data;
                if (err.response.status === 400) {
                    setLoading(false);
                    swal({text: API_MESSAGE.message, icon: "warning", dangerMode: true});
                } else {
                    console.log(err.response.status);
                    let data = <tr>
                        <td colSpan="5"><p className="text-center">{API_MESSAGE.message}</p></td>
                    </tr>
                    setTableData(data);
                    setLoading(false);
                }
            })
    }

    // useEffect(() => {
    //     if (isFilterError === false) {
    //         GetRole()
    //     }
    //     return () => {
    //     }
    // }, [isLoading, isFilterError]);

    async function GetRole() {
        let Role = await useGetRoleModule("faqs");
        if(Role.moduleList.read === false){
            setPermission({moduleAccress : false, moduleList:{}, message:"Module Need Some Permission...Pls contact with Your Partner"})
        }else{
            setPermission(Role)
            CALLAPI(1, '');
        }
        
    }

    useEffect(() => {
        GetRole()
    }, []);

    // pagination handler
    const paginationHandler = (page) => {
        let selectedValue = parseInt($('#filter').val());
        if (selectedValue === 2) {
            CALLAPI(page, '');
        } else {
            CALLAPI(page, selectedValue);
        }
    }


    // update status handler 
    const StatusHandler = async (url, postData) => {
        // console.log('called', url, postData)
        let data = await patchApiCall(url, postData);
        if (data.meta.status) {
            // CALLAPI(1, '');
            swal({text: data.meta.msg, icon: "success", timer: 1500});
            window.location.reload();
        }
    }

    const DeleteHandler = async (url, postData) => {
        deleteApiCall(url, postData)
            .then(data => {
                if (data.meta.status) {
                    swal({text: data.meta.msg, icon: "success", timer: 1500});
                    CALLAPI(1, '');
                }
            })
    }

    let renderAllData = '';
    if (isLoading === true) {
        if (allData.length > 0) {
            // console.log(allData);
            renderAllData = allData.map((data, index) => {
                let sNo = (current === 1) ? index + 1 : index + 1 + (current * 10);
                let status = (data.status === 'ACTIVE') ? 1 : 0;
                console.log(data.question, data.status, status)

                return <tr key={data._id}>
                    <th scope="row">{sNo}</th>
                    <td>{data.question}</td>
                    <td>{(data.answer.length > 180) ? `${data.answer.substring(1, 183)}...` : data.answer}</td>
                    <td>{(status) ? <span className="text-success">Active</span> :
                        <span className="text-danger">Deactivate</span>}</td>
                    {permission.moduleList.deleteDisabled && permission.moduleList.updateDisabled ? null : <td style={{width: 118}}>
                    {permission.moduleList.updateDisabled ? null :
                        <EnableDisable id={data._id} status={status}
                                       apiurl="common/faq/changeStatus" postData={{
                            status: !status ? 'active' : 'deactive',
                            faqId: data.faqId
                        }} handler={StatusHandler}/> }
                        

                       

                        {permission.moduleList.updateDisabled ? null :
                        <Link  to={`/faqs/editFaqs/${data.faqId}`}
                                                      className="btn btn-primary btn-icon-split btn-sm mb-1 mr-1">
                                                    <span className="icon text-white-50">
                                                        <i className="far fa-edit"></i>
                                                    </span>
                                                    <span className="text">Edit</span>
                                                </Link> }

                                                {permission.moduleList.deleteDisabled ? null :
                                                <DeleteBtn id={data.faqId}
                                   handler={() => DeleteHandler(`common/faq/delete/${data.faqId}`, {})}/> }
                        
                                                </td>}
                </tr>;
            })
            setTableData(renderAllData)
            setLoading(false);
        }
    }


    return (
        <div>
            {/* navigation */}
           
            {/* **************core-container************ */}
            <div className="container-fluid">
            {permission.hasOwnProperty('moduleAccress') && !permission.moduleAccress ?
                    <div className="row text-center">
                        <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                            <div className="errer">
                                <img src="/program-error.png"/>
                                <h2>403</h2>
                                {/* <h4 className="text-danger">{permission.message}</h4> */}
                                <p>{permission.message}</p>

                            </div>
                        </div>
                    </div>
                    :
                    (Object.keys(permission).length > 0) ?
                <>
            <div className="main-title">
            <h3>FAQs </h3>
                </div>
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-12 align-self-center">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb m-0 p-0">
                                    <li className="breadcrumb-item"><Link to={pageUrl}>{pageName} Management</Link>
                                    </li>
                                </ol>
                            </nav>
                            {permission.moduleList.createDisabled ? null :
                            <Link to="/faqs/addFaqs">
                                            <button type="submit"
                                                    className="d-sm-inline-block btn btn-sm btn-warning shadow-sm"><i
                                                className="fas fa-plus"></i> Create
                                            </button>
                                        </Link>}
                        </div>

                    </div>
                    {/* <div className="form-group col-md-6 text-right">
                        <button type="submit" className="btn btn-info">Create city</button>
                    </div> */}

                </div>
            </div>
                <div className="row">
                    <div className="col-12">

                        <div className="card">
                           
                            <div className="table-responsive mb-3">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">Sl. No.</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Status</th>
                                        {permission.moduleList.deleteDisabled && permission.moduleList.updateDisabled ? null : <th scope="col">Actions</th> }
                                    </tr>
                                    </thead>
                                    <tbody>{tableData}</tbody>
                                </table>
                                <Pagination prev={previous} current={current} next={next} pageCount={totalPage}
                                            handler={paginationHandler}/>
                            </div>
                        </div>
                    </div>
                </div></> : null}
            </div>
        </div>
    )
}

export default FaqsListing
