// import package and components
import React, {Component, useState} from 'react';
import './App.css';
import './custom.css';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import IsAuthenticat from './IsAuthenticat';
import Dashboard from './Pages/Dashboard';
import Login from "./Components/Login";
import AmenitiesManagement from "./Components/Amenities/AmenitiesManagement";
import AddAmenities from "./Components/Amenities/AddUpdate";
import PropertyTypeListing from "./Pages/PropertyType/PropertyTypeListing";
import PropertyTypeAdd from "./Pages/PropertyType/PropertyTypeAdd";
import CategoryListing from "./Pages/Categories/CategoryListing";
import CategoryAdd from "./Pages/Categories/CategoryAdd";
import BlogListing from "./Pages/Blogs/BlogListing";
import BlogCreate from "./Pages/Blogs/BlogCreate";
import AdminAccess from "./AdminAccess";
import PropertyListing from "./Pages/Properties/PropertyListing";
import PropertyAdd from "./Pages/Properties/PropertyAdd";
import StateListing from "./Pages/States/StateListing";
import StateAdd from "./Pages/States/StateAdd";
import CityListing from "./Pages/City/CityListing";
import Layout from "./Layout";
import PartnerManagement from './Pages/Partner/DataManagement';
import AddUpdatePartner from './Pages/Partner/AddUpdate';
import AddUserPartner from './Pages/Partner/AddUserPartner';
import UserPartnerManagement from './Pages/Partner/UserPartnerManagement';
import FaqAdd from "./Pages/Faqs/FaqAdd";
import FaqsListing from "./Pages/Faqs/FaqsListing";
import UpdateUserPartner from "./Pages/Partner/UpdateUserPartner";
import CustomerListing from "./Pages/Customer/CustomerListing";
import CmsListing from "./Pages/cms/CmsListing";
import CustomerAdd from "./Pages/Customer/CustomerAdd";
import CmsAdd from "./Pages/cms/CmsAdd";
import BlogCategoryListing from "./Pages/BlogCategory/BlogCategoryListing";
import BlogCategoryCreate from "./Pages/BlogCategory/BlogCategoryCreate";
import PropertyBulkUpload from "./Pages/Properties/PropertyBulkUpload";
import PropertyBids from "./Pages/Properties/PropertyBids";
import LeadsListing from "./Pages/Lead/LeadsListing";
import LeadsAdd from "./Pages/Lead/LeadsAdd";
import LeadsUserPreference from './Pages/Lead/LeadsUserPreference'
import LeadCommunications from './Pages/Lead/LeadCommunications';
import PropertyAuctionBulkUpload from "./Pages/Properties/PropertyAuctionBulkUpload";
import FaqsCategoryListing from "./Pages/FaqsCategory/FaqsCategoryListing";
import FaqsCategoryCreate from "./Pages/FaqsCategory/FaqsCategoryCreate";
import PartnerAdd from "./Pages/Partner/PartnerAdd";
import PropertyAuctionAdd from "./Pages/Properties/PropertyAuctionAdd";
import CityAdd from "./Pages/City/CityAdd";
import PLanAdd from "./Pages/plans/PlanAdd";
import PlanListing from "./Pages/plans/PlanListing";
import BidderListing from "./Pages/Bidder/BidderListing";
import Permission from './Pages/Partner/Permission';
import Role from './Pages/Partner/Role';
import PropertyDetails from "./Pages/Properties/PropertyDetails";
import BulkImageUpload from './Pages/Properties/BulklImageUpload'
import BulkDocumentUpload from './Pages/Properties/BulkDocumentUpload';
import BidderDetial from "./Pages/Bidder/BidderDetial";
import PropertyFeedbacks from "./Pages/Properties/PropertyFeedbacks";
import getApiCall from "./Services/getApiCall";
import {decryptBody} from "./Services/helpers";
import PropertyBulkUpdate from "./Pages/Properties/PropertyBulkUpdate";
import LiveBid from "./Pages/LiveBid/LiveBid";
import PropertyDetail from './Pages/LiveBid/PropertyDetail';
import LeadComments from "./Pages/Lead/LeadComments";
import LiveBidderList from "./Pages/LiveBid/LiveBidderList";
import { io } from 'socket.io-client';
import Constant from "../src/Components/Constant";
import PrimaryDetails from './Pages/Bidder/PrimaryDetails';
import DocumentDetails from './Pages/Bidder/DocumentDetails';
import PaymentDetails from './Pages/Bidder/PaymentDetails';
import DeclarativeDocuments from './Pages/Bidder/DeclarativeDocument';
import BidderProperties from "./Pages/Bidder/BidderProperties";
import AddressLocationListing from "./Pages/AddressLocation/AddressLocationListing";
import AddressLocationAdd from "./Pages/AddressLocation/AddAddressLocation";
import EmdPayments from "./Pages/EmdPayment/EmdPaymentList";
import PaymentVerification from "./Pages/Bidder/PaymentVerification";
import BidderOverview from './Pages/Bidder/BidderOverview';
import AuctionPropertyListing from './Pages/Properties/AuctionPropertyListing';
import AuditTrails from './Pages/Properties/AuditTrail'
import partnerAuditTrails from './Pages/Partner/AuditTrail'

/* End */

class App extends Component {

    // run on first render
    state = {
        time: 'fetching',
        socket: io(Constant.socketPath)
    }


    // Do Not remove its used for Blob File Token
//    componentDidMount() {
//        getApiCall('common/upload/blob/token')
//            .then(response => {
//                sessionStorage.setItem('uploadT', decryptBody(response.data).token)
//             })
//     }


    render() {

        return (
            <Router>
                <Switch>
                    <Route exact strict path="/" component={Login}/>
                    <Route path="/login" component={Login}/>
                    <Route render={() => (IsAuthenticat() === true) ? <DefaultContainer socket={this.state.socket}/> : <Login />}/>
                    <Route render={() => <h1>404: page not found</h1>}/>
                </Switch>
            </Router>

        )
    }
}


//To be change in to default router
const DefaultContainer = (props) => 
    (
    <Layout>
       
        <AdminAccess exact strict path="/dashboard" component={Dashboard}/>
        <AdminAccess exact strict path="/amenities" component={AmenitiesManagement}/>
        <AdminAccess exact strict path="/amenities/add" component={AddAmenities}/>
        <AdminAccess exact strict path="/customers" component={CustomerListing}/>
        <AdminAccess exact strict path="/customers/add" component={CustomerAdd}/>
        <AdminAccess exact strict path="/property-types" component={PropertyTypeListing}/>
        <AdminAccess exact strict path="/property-types/add" component={PropertyTypeAdd}/>
        <AdminAccess exact strict path="/properties" component={PropertyListing}/>
        <AdminAccess exact strict path="/properties/auction" component={AuctionPropertyListing}/>
        
        <AdminAccess exact strict path="/property/add" component={PropertyAdd}/>
        <AdminAccess exact strict path="/property/details/:id" component={PropertyDetails}/>

        <AdminAccess exact strict path="/property/auction/add" component={PropertyAuctionAdd}/>
        <AdminAccess exact strict path="/properties/bids/:id" component={PropertyBids}/>
        <AdminAccess exact strict path="/properties/feedbacks/:id" component={PropertyFeedbacks}/>
        <AdminAccess exact strict path="/properties/feedbacks" component={PropertyFeedbacks}/>
        <AdminAccess exact strict path="/properties/audit-trails/:id" component={AuditTrails}/>


        <AdminAccess exact strict path="/properties/bulk-update" component={PropertyBulkUpdate}/>
        <AdminAccess exact strict path="/property/bulk-upload" component={PropertyBulkUpload}/>
        <AdminAccess exact strict path="/property/auction-bulk-upload" component={PropertyAuctionBulkUpload}/>
        <AdminAccess exact strict path="/property/bulk-image-upload" component={BulkImageUpload}/>
        <AdminAccess exact strict path="/property/bulk-doucument-upload" component={BulkDocumentUpload}/>
        <AdminAccess exact strict path="/leads" component={LeadsListing}/>
        {/*<AdminAccess exact strict path="/leads/add" component={LeadsAdd}/>*/}
        <AdminAccess exact strict path="/leads_preference" component={LeadsUserPreference}/>
        <AdminAccess exact strict path="/leads_communications" component={LeadCommunications}/>
        <AdminAccess exact strict path="/states" component={StateListing}/>
        {/*<AdminAccess exact strict path="/states/add" component={StateAdd}/>*/}
        <AdminAccess exact strict path="/cities" component={CityListing}/>
        <AdminAccess exact strict path="/edit-city/:id" component={CityAdd}/>
        <AdminAccess exact strict path="/add-city/" component={CityAdd}/>
        <AdminAccess exact strict path="/states/add" component={StateAdd}/>
        <AdminAccess exact strict path="/address-locations" component={AddressLocationListing}/>
        <AdminAccess exact strict path="/address-locations/add" component={AddressLocationAdd}/>
        <AdminAccess exact strict path="/address-locations/edit/:id" component={AddressLocationAdd}/>
        <AdminAccess exact strict path="/categories" component={CategoryListing}/>
        <AdminAccess exact strict path="/categories/add" component={CategoryAdd}/>

        <AdminAccess exact strict path="/blogs" component={BlogListing}/>
        <AdminAccess exact strict path="/blogs/add" component={BlogCreate}/>
        <AdminAccess exact strict path="/blogs/categories" component={BlogCategoryListing}/>
        <AdminAccess exact strict path="/blogs/categories/add" component={BlogCategoryCreate}/>

        <AdminAccess exact strict path="/cms" component={CmsListing}/>
        <AdminAccess exact strict path="/cms/add" component={CmsAdd}/>

        <AdminAccess exact strict path="/partner-management" component={PartnerManagement}/>
        <AdminAccess exact strict path="/Permission/:id" component={Permission}/>
        <AdminAccess exact strict path="/create-partner" component={AddUpdatePartner}/>
        <AdminAccess exact strict path="/edit-partner/:id" component={AddUpdatePartner}/>
        <AdminAccess exact strict path="/partner-users/:id/:name" component={UserPartnerManagement}/>
        <AdminAccess exact strict path="/add-user-partner/:id/:name" component={AddUserPartner}/>
        <AdminAccess exact strict path="/edit-user-partner/:id" component={UpdateUserPartner}/>
        <AdminAccess exact strict path="/role/:id/:role" component={Role}/>
        <AdminAccess exact strict path="/partner-user-audit-trail/:id" component={partnerAuditTrails}/>

        

        <AdminAccess exact strict path="/faqs" component={FaqsListing}/>
        <AdminAccess exact strict path="/faqs/addFaqs" component={FaqAdd}/>
        <AdminAccess exact strict path="/faqs/editFaqs/:id" component={FaqAdd}/>
        <AdminAccess exact strict path="/faqs/categories" component={FaqsCategoryListing}/>
        <AdminAccess exact strict path="/faqs/categories/add" component={FaqsCategoryCreate}/>

        <AdminAccess exact strict path="/plans" component={PlanListing}/>
        <AdminAccess exact strict path="/plans/add" component={PLanAdd}/>
        <AdminAccess exact strict path="/bidders/detail/:id" component={BidderDetial}/>
        
        <AdminAccess exact strict path="/bidders" component={BidderListing}/>
        <AdminAccess exact strict path="/bidder/bidder_overviews/:id" component={BidderOverview}/>
        <AdminAccess exact strict path="/bidders/properties/:bidderId" component={BidderProperties}/>
        <AdminAccess exact strict path="/live_bid" component={LiveBid} socket={props.socket}/>
        <AdminAccess exact strict path="/Property_Details/:id" component={PropertyDetail} socket={props.socket}/>
        <AdminAccess exact strict path="/Leads_Comments/:id" component={LeadComments}/>
        <AdminAccess exact strict path="/liveBidderList/:id" component={LiveBidderList} socket={props.socket}/>
        
        <AdminAccess exact strict path="/Primary_Details/:id" component={PrimaryDetails}/>
        <AdminAccess exact strict path="/Document_Details/:id" component={DocumentDetails}/>
        <AdminAccess exact strict path="/Payment_Details/:id" component={PaymentDetails}/>
        <AdminAccess exact strict path="/Payment_Verification/:id" component={PaymentVerification}/>
        <AdminAccess exact strict path="/Declarative_Documents/:id" component={DeclarativeDocuments}/>
        <AdminAccess exact strict path="/emd-payments" component={EmdPayments}/>

    </Layout>

)


export default App;