import React, {useState, useEffect, useRef} from 'react'
import getApiCall from "../../Services/getApiCall";
import {useHistory, useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import {FaRegCommentAlt} from 'react-icons/fa';
import Pagination from '../../Widgets/Pagination';
import {Link} from "react-router-dom";
import useGetRoleModule from '../../Services/useGetRoleModule';


function LeadCommunications() {
    const history = useHistory();
    const location = useLocation();

    const [phone, setPhone] = useState('');
    const [search, setSearch] = useState('');
    const [toDt, setToDt] = useState("");
    const [fromDt, setfromDt] = useState("")
    const [communicationData, setCommunicationData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setpage] = useState(1);
    const [permission, setPermission] = useState({})


    const paginationHandler = (page) => {
        setpage(page)
        getLeadCommunications(fromDt, toDt, search, page, "", phone);
        // getLeadCommunications("", "", "", page, "");
    }


    async function getLeadCommunications(fromDate, toDate, searchKey, page, contentPerPage, searchByPhoneEmailName = null) {

        let response = await getApiCall(`common/send-enquiry/list2?fromDate=${fromDate}&toDate=${toDate}&searchKey=${searchKey}&page=${page}&contentPerPage=${contentPerPage}&searchByPhoneEmailName=${searchByPhoneEmailName}`);
        setCommunicationData(response.data);
        setTotalPage(response.pages)
    }


    async function GetRole() {
        let Role = await useGetRoleModule("leads_communications");
        setPermission(Role)
        getLeadCommunications("", "", "", page, "");
    }


    useEffect(() => {
        GetRole();
    }, [])


    return (
        <>
            <div className='container-fluid'>
                <div className="main-title">
                    <h3>Lead Communications</h3>
                </div>

                {permission.hasOwnProperty('moduleAccress') && !permission.moduleAccress ?
                    <div className="row text-center">
                        <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                            <div className="errer">
                                <img src="/program-error.png"/>
                                <h2>403</h2>
                                {/* <h4 className="text-danger">{permission.message}</h4> */}
                                <p>{permission.message}</p>

                            </div>
                        </div>
                    </div>
                    :

                <div className='card'>
                    <div className='card-body'>

                        <div className='row'>
                            <div className='col col-md-3'>
                                <label className="font-weight-bold">Name | Phone No | Email</label>
                                <div className="form-group">
                                    <input type="text" className="form-control" onChange={(e) => {
                                        setSearch(e.target.value)
                                    }
                                    } value={search}/>

                                </div>
                            </div>


                            <div className='col col-md-3'>
                                <label className="font-weight-bold">Search By Preference</label>
                                <div className="form-group">
                                    <input type="text" className="form-control"

                                        //        onChange={(e) => {
                                        //     setSearch(e.target.value)
                                        // }}
                                        // value={search}
                                    />

                                </div>
                            </div>

                            <div className='col col-md-2'>
                                <label className="font-weight-bold">Start Date</label>
                                <div className="form-group">
                                    <input type="date" value={fromDt} className="form-control"
                                           onChange={(e) => setfromDt(e.target.value)}/>
                                </div>
                            </div>

                            <div className='col col-md-2'>
                                <label className="font-weight-bold">End Date</label>
                                <div className="form-group">
                                    <input type="date" value={toDt} className="form-control"
                                           onChange={(e) => setToDt(e.target.value)}/>
                                </div>
                            </div>


                            <div className='col col-md-2'>
                                <div className="form-group mt30">
                                    <button className='btn btn-warning'
                                            onClick={(e) => {
                                                getLeadCommunications(fromDt, toDt, search, page, "", phone);
                                            }
                                            }
                                    >Search
                                    </button>
                                </div>
                            </div>


                        </div>

                        <div className="table-responsive mt-5">
                            <table className="table table-bordered" width="100%" cellSpacing="0">
                                <thead>
                                <tr>
                                    <th>Lead</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Title</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Property Address</th>
                                    <th>Price</th>
                                    {permission.moduleList?.updateDisabled ? null : <th>Comment</th> }

                                </tr>
                                </thead>

                                <tbody>
                                {communicationData.map((item, index) => {
                                    let propertyData = item.propertyData
                                    return (

                                        <tr key={index}>
                                            <td>{(page * 10) + (index + 1) - 10}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.mobile}</td>
                                            <td>{propertyData != undefined ? item.propertyData.propertyType : "-"}  </td>
                                            <td> {propertyData != undefined ? item.propertyData.stateName : "-"} </td>
                                            <td>{propertyData != undefined ? item.propertyData.cityName : "-"} </td>
                                            <td> {propertyData != undefined ? item.propertyData.address.split('') : "-"}</td>
                                            <td> {propertyData != undefined ? item.propertyData.price : "-"}</td>
                                            {permission.moduleList?.updateDisabled ? null : <td>
                                                <div>
                                                    <Link to={`Leads_Comments/${item.contactUsId}`}>
                                                        <FaRegCommentAlt size={15}/>
                                                    </Link>
                                                </div>
                                                <div>
                                                    {location.state && location.state.id != null && item._id == location.state.id ? location.state.params : " "}
                                                </div>

                                            </td> }

                                        </tr>

                                    )


                                })}
                                </tbody>
                            </table>
                        </div>

                        <Pagination prev={page == 1 ? 1 : (page > 1) ? page - 1 : page} current={page}
                                    next={page + 1} pageCount={totalPage} handler={paginationHandler}/>

                    </div>

                </div> }
            </div>


        </>
    )
}

export default LeadCommunications