// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-preview {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
}

.img-preview__item {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0.5rem;
}

.img-preview__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-preview__remove {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 0.75rem;
    background: #000;
    color: #fff;
    font-size: 0.75rem;
    line-height: 1;
    cursor: pointer;
}

.img-preview__remove:hover {
    background: #f00;
}

.preview__video {
    width: 300px;
    height: 200px;
}



/* Chrome, Safari, Edge, Opera */
.hide-number-input-arrow input::-webkit-outer-spin-button,
.hide-number-input-arrow input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.hide-number-input-arrow input[type=number] {
    -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
 input[type=number] {
    -moz-appearance: textfield;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,uBAAuB;IACvB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;;;AAIA,gCAAgC;AAChC;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA,YAAY;AACZ;IACI,0BAA0B;AAC9B;;AAEA,gCAAgC;CAC/B;;IAEG,wBAAwB;IACxB,SAAS;AACb;;AAEA,YAAY;CACX;IACG,0BAA0B;AAC9B","sourcesContent":[".img-preview {\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0 -0.5rem;\n}\n\n.img-preview__item {\n    position: relative;\n    width: 100px;\n    height: 100px;\n    margin: 0.5rem;\n}\n\n.img-preview__image {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.img-preview__remove {\n    position: absolute;\n    top: 0;\n    right: 0;\n    padding: 0.5rem 0.75rem;\n    background: #000;\n    color: #fff;\n    font-size: 0.75rem;\n    line-height: 1;\n    cursor: pointer;\n}\n\n.img-preview__remove:hover {\n    background: #f00;\n}\n\n.preview__video {\n    width: 300px;\n    height: 200px;\n}\n\n\n\n/* Chrome, Safari, Edge, Opera */\n.hide-number-input-arrow input::-webkit-outer-spin-button,\n.hide-number-input-arrow input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n/* Firefox */\n.hide-number-input-arrow input[type=number] {\n    -moz-appearance: textfield;\n}\n\n/* Chrome, Safari, Edge, Opera */\n input::-webkit-outer-spin-button,\n input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n/* Firefox */\n input[type=number] {\n    -moz-appearance: textfield;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
