
    function getAccessToken() {
        // let token = sessionStorage.getItem('loginDetails');
        // let getDetails = JSON.parse(token);
        // return  getDetails;
        return sessionStorage.getItem('loginDetails');
    }

    module.exports = {
        'getAccessToken': getAccessToken
    }